<template>
  <b-card no-body class="overflow-hidden mb-8">
    <b-row no-gutters>
      <b-col lg="4" style="background-color: white">
        <b-card-img
          v-if="shopitem.img_url !== '' && shopitem.img_url"
          :src="shopitem.img_url"
          class="rounded-0"
        ></b-card-img>
      </b-col>
      <b-col lg="8">
        <b-card-body :title="shopitem.name">
          <b-card-text>
            {{ shopitem.descr }}
          </b-card-text>
          <b-card-text>
            <b-row>
              <b-col lg="3">
                <h5 style="margin-top: 10px;">{{ shopitem.price_sek }} {{ shopitem.currency }}</h5>
              </b-col>

              <b-col lg="3">
                <b-form-input
                  id="num_bought"
                  v-model="num_bought"
                  placeholder="0"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col lg="3">
                <b-button
                  href="#"
                  variant="primary"
                  @click="addShopItem(shopitem.shop_item_id, $event)"
                  >{{$t('COMMON.ADD')}}</b-button
                >
              </b-col>
              <b-col lg="3">
                <b-button
                  v-if="num_bought > 0"
                  href="#"
                  variant="danger"
                  @click="removeShopItem(shopitem.shop_item_id, $event)"
                  >{{$t('COMMON.REMOVE')}}</b-button
                >
              </b-col>
              <b-col lg="3">
                <h5 style="margin-top: 10px; font-weight: 100;">
                  {{ shopitem.price_sek * num_bought }} {{ shopitem.currency }}
                </h5>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>


<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'shop-extra-item',
  props: ['shopitem'],
  emits: ['addShopItem', 'removeShopItem'],
  mixins: [ toasts ],
  components: {},
  data() {
    return {
      num_bought: 0
    };
  },
  mounted() {},
  watch: {},
  methods: {
    clear() {
      this.num_bought = 0;
    },
    removeShopItem(shop_item_id, e) {
      e.preventDefault();

      this.num_bought--;

      this.$emit('removeShopItem', shop_item_id);
    },
    addShopItem(shop_item_id, e) {
      e.preventDefault();

      this.num_bought++;

      this.$emit('addShopItem', shop_item_id);
    },

  }
};
</script>
