<template>

  <div>
  <b-row class="mt-4">

    <b-col cols="6">
      <b-row>
        <b-col v-if="!is_mobile" cols="2"><img class="item-icon" src="@/assets/images/membership_icon.png" /></b-col>
        <b-col cols="10" class="item-descr">
          <h5 style="margin-top: 10px;">{{ item.name }}</h5>
          <p>{{ (item.descr === '' || !item.descr) ? item.company_name : item.descr }}</p>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="2" class="amounts-cols text-right">
      <h5>{{ item.quantity }}</h5>
    </b-col>

    <b-col cols="2" class="amounts-cols text-right">
      <h5>{{ item.price_sek }} {{ item.currency }}</h5>
    </b-col>

    <b-col cols="1">
      <a
        v-if="!item.is_membership"
        href="#"
        class="btn btn-icon btn-light btn-sm"
        @click="deleteItemClicked($event, item.shop_item_id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </b-col>
  </b-row>
</div>

</template>

<script>
import { is_mobile } from '@/core/services/utils';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'shopcart-table-row',
  mixins: [ toasts ],
  props: ['item'],
  emits: ['deleteItemClicked'],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    is_mobile() {
      return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height;
    }
  },
  watch: {},
  methods: {
    deleteItemClicked(e, shop_item_id) {
      e.preventDefault();
      this.$emit('deleteItemClicked', shop_item_id);
    }
  }
};
</script>

<style scoped>

  .cart-header h4 {
    text-transform: uppercase;
    padding: 8px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    color: #6c6c6c;
  }
  .cart-header {
    background-color: #efeff2;
  }
  .item-icon {
    max-width: 64px;
  }
  .item-descr {

  }
  .item-discount {
    color: #fd397a;
  }
  .item-descr h5 {
    margin-top: 10px;
  }
  .item-descr p {
    font-style: italic;
    color: #8a8a8a;
  }
  .table > thead > tr > th:first-child, .table > th:last-child {
    border-radius: 0;
  }
  .amounts-cols h5 {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 1rem;
  }
</style>
