<template>
  <span class="svg-icon svg-icon-sm">
    <inline-svg src="/assets/svg/question-circle-fill.svg" v-b-tooltip="{ title: tip, placement: 'top', boundary: 'window' }"></inline-svg>
  </span>
</template>

<script>
export default {
  name: 'tip-icon',
  props: {
    tip: { type: String, required: false}
  }
}
</script>

<style scoped>
.tooltip { font-size: 1.0em; }
</style>
